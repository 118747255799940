// @mui material components
import Icon from "@mui/material/Icon";

// @mui icons
import LinkedIn from "@mui/icons-material/LinkedIn";

// Pages
import AboutUs from "layouts/pages/landing-pages/about-us";
import Clinics from "layouts/pages/landing-pages/clinics";
import Blog from "layouts/pages/landing-pages/blog";
import FAQs from "pages/LandingPages/FAQ";
import Terms from "pages/LandingPages/Terms";
import Privacy from "pages/LandingPages/Privacy";

const routes = [
  {
    name: "pages",
    icon: <Icon>dashboard</Icon>,
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "landing pages",
        collapse: [
          {
            name: "about us",
            route: "/pages/landing-pages/About",
            component: <AboutUs />,
          },
          {
            name: "clinics",
            route: "/pages/landing-pages/Clinics",
            component: <Clinics />,
          },
          {
            name: "blogs",
            route: "/pages/landing-pages/Blogs",
            component: <Blog />,
          },
          {
            name: "faqs",
            route: "/pages/landing-pages/FAQs",
            component: <FAQs />,
          },
          {
            name: "terms",
            route: "/pages/landing-pages/Terms",
            component: <Terms />,
          },
          {
            name: "privacy",
            route: "/pages/landing-pages/Privacy",
            component: <Privacy />,
          },
        ],
      },
    ],
  },
  {
    name: "linkedIn",
    icon: <LinkedIn />,
    href: "https://www.linkedin.com/in/cristofher-alvarado-370891254/",
  },
];

export default routes;
