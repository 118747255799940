import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "UI/Navbars/DefaultNavbar";
import DefaultFooter from "UI/Footers/index.js";
import routes from "routes";
import footerRoutes from "footer.routes";
import "App.css";

function FAQ() {
  const faqs = [
    {
      question: "What is Medicare Advantage?",
      answer:
        "Medicare Advantage is a type of health plan offered by private companies that contract with Medicare to provide all your Part A and Part B benefits. Most plans also include prescription drug coverage Part D.",
    },
    {
      question: "What are the benefits of Medicare Advantage plans?",
      answer:
        "Medicare Advantage plans often provide additional benefits beyond original Medicare, such as dental, vision, and hearing services, as well as wellness programs.",
    },
    {
      question: "How do I enroll in a Medicare Advantage plan?",
      answer:
        "You can enroll in a Medicare Advantage plan during the Annual Enrollment Period (AEP), which runs from October 15 to December 7 each year. You can also enroll when you first become eligible for Medicare.",
    },
    {
      question: "Can I switch my Medicare Advantage plan?",
      answer:
        "Yes, you can switch your Medicare Advantage plan during the AEP or if you qualify for a Special Enrollment Period due to specific circumstances.",
    },
    {
      question: "What is the Open Enrollment Period (OEP) for Medicare?",
      answer:
        "The Open Enrollment Period (OEP) for Medicare runs from January 1 to March 31 each year, allowing you to switch from a Medicare Advantage plan to Original Medicare or vice versa.",
    },
    {
      question: "What is life insurance?",
      answer:
        "Life insurance is a contract between an individual and an insurance company, where the company pays a specified amount to the beneficiaries upon the death of the insured person.",
    },
    {
      question: "What are the different types of life insurance?",
      answer:
        "The main types of life insurance are term life insurance, whole life insurance, universal life insurance, and indexed universal life insurance. Each type has different features and benefits.",
    },
    {
      question: "How much life insurance do I need?",
      answer:
        "The amount of life insurance you need depends on various factors, including your financial obligations, dependents, and personal preferences. A common rule of thumb is to have coverage equal to 10-15 times your annual income.",
    },
    {
      question: "What is the Affordable Care Act (ACA)?",
      answer:
        "The Affordable Care Act (ACA) is a comprehensive health care reform law enacted in March 2010 that aims to increase health insurance coverage and reduce costs for individuals and families.",
    },
    {
      question: "How can I enroll in an ACA health plan?",
      answer:
        "You can enroll in an ACA health plan during the Open Enrollment Period, which typically runs from November to December each year. Outside of this period, you can enroll if you qualify for a Special Enrollment Period due to certain life events.",
    },
    {
      question: "What is the Open Enrollment Period (OEP) for ACA health plans?",
      answer:
        "The Open Enrollment Period (OEP) for ACA health plans typically takes place from November 1st and lasts until mid-January, allowing individuals to sign up for or change their health coverage.",
    },
    {
      question: "What are the benefits of ACA health plans?",
      answer:
        "ACA health plans must cover essential health benefits, including preventive services, emergency services, maternity care, and prescription drugs, without charging higher premiums for pre-existing conditions.",
    },
  ];

  return (
    <>
      {/* Navbar Section */}
      <MKBox position="fixed" top="0.5rem" width="100%" zIndex={10}>
        <DefaultNavbar routes={routes} />
      </MKBox>

      {/* Main Content Section */}
      <MKBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        py={6}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(/images/bg-about-us.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          position: "relative",
        }}
      >
        <MKBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          zIndex={1}
          sx={{
            width: "100%", // Ensure full width for the container
            maxWidth: "1200px", // Limit maximum width for better alignment
          }}
        >
          <MKTypography variant="h4" color="white" gutterBottom>
            Frequently Asked Questions
          </MKTypography>
          <MKBox
            display="flex"
            flexDirection="column"
            gap={2} // Space between questions
            sx={{ width: "100%", margin: "0 auto" }} // Full width container
          >
            {faqs.map((faq, index) => (
              <MKBox
                key={index}
                sx={{
                  backgroundColor: "#f5f5f5", // Light gray background
                  borderRadius: "8px", // Rounded corners
                  padding: "16px", // Padding for spacing
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow
                }}
              >
                <MKTypography variant="h6" color="black">
                  {faq.question}
                </MKTypography>
                <MKTypography
                  variant="body1"
                  color="black"
                  sx={{
                    margin: "10px 0", // Add margin for spacing
                  }}
                >
                  {faq.answer}
                </MKTypography>
              </MKBox>
            ))}
          </MKBox>
        </MKBox>
      </MKBox>

      {/* Footer Section */}
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default FAQ;
