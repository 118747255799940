import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "UI/Navbars/DefaultNavbar";
import DefaultFooter from "UI/Footers/index.js";
import routes from "routes";
import footerRoutes from "footer.routes";
import "App.css";

function Clinics() {
  const clinics = [
    {
      name: "Oak Street Health",
      description:
        "Provides quality care tailored for seniors, focusing on preventive health and chronic disease management.",
      logo: "/clinics/oakst.png",
    },
    {
      name: "Suvida Health",
      description:
        "A Medicare Advantage provider that offers personalized care and a wide range of services to improve health outcomes.",
      logo: "/clinics/suvida.png",
    },
    {
      name: "CenterWell",
      description:
        "Offers comprehensive primary care and specialty services for seniors, emphasizing health and wellness.",
      logo: "/clinics/center.png",
    },
    {
      name: "WellMed",
      description:
        "Focuses on providing high-quality healthcare services for seniors, with a range of specialists and preventive care.",
      logo: "/clinics/well.png",
    },
    {
      name: "Kelsey Seybold Clinic",
      description:
        "An integrated healthcare system that offers comprehensive services, including primary and specialty care.",
      logo: "/clinics/seybold.png",
    },
  ];

  return (
    <>
      {/* Navbar Section */}
      <MKBox position="fixed" top="0.5rem" width="100%" zIndex={10}>
        <DefaultNavbar routes={routes} />
      </MKBox>

      {/* Main Content Section */}
      <MKBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        py={6}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(/images/bg-about-us.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          position: "relative",
        }}
      >
        <MKBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          zIndex={1}
          sx={{
            width: "100%", // Ensure full width for the container
            maxWidth: "1200px", // Limit maximum width for better alignment
          }}
        >
          <MKTypography variant="h4" color="white" gutterBottom>
            Popular Clinics
          </MKTypography>
          <MKBox
            display="flex" // Use Flexbox for alignment
            flexWrap="wrap" // Allow wrapping
            justifyContent="center" // Center the boxes
            gap={2} // Space between boxes
            sx={{ width: "100%", margin: "0 auto" }} // Full width container
          >
            {clinics.map((clinic, index) => (
              <MKBox
                key={index}
                my={2}
                textAlign="center"
                sx={{
                  backgroundColor: "#f5f5f5", // Light gray background
                  borderRadius: "8px", // Rounded corners
                  padding: "16px", // Padding for spacing
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow
                  flex: "1 1 200px", // Responsive width
                  height: "auto", // Allow height to adjust automatically
                  display: "flex", // Flexbox to center content
                  flexDirection: "column", // Vertical alignment
                  justifyContent: "space-between", // Space out the content
                }}
              >
                <div>
                  <MKTypography variant="h5" color="black">
                    {clinic.name}
                  </MKTypography>
                  <MKTypography
                    variant="body1"
                    color="black"
                    sx={{
                      margin: "10px 0", // Add margin for spacing
                      // Removed height, overflow, and textOverflow properties
                      display: "block", // Make it a block element
                    }}
                  >
                    {clinic.description}
                  </MKTypography>
                </div>
                <img
                  src={clinic.logo}
                  alt={`${clinic.name} logo`}
                  style={{ width: "100px", marginTop: "10px" }} // Adjust width and margins as needed
                />
              </MKBox>
            ))}
          </MKBox>
        </MKBox>
      </MKBox>

      {/* Footer Section */}
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Clinics;
