import React from "react";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "UI/Navbars/DefaultNavbar";
import DefaultFooter from "UI/Footers/index.js";
import routes from "routes";
import footerRoutes from "footer.routes";
import "App.css";

function Terms() {
  return (
    <>
      {/* Navbar Section */}
      <MKBox position="fixed" top="0.5rem" width="100%" zIndex={10}>
        <DefaultNavbar routes={routes} />
      </MKBox>

      {/* Main Content Section */}
      <MKBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        py={6}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(/images/bg-about-us.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          position: "relative",
        }}
      >
        <MKBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          zIndex={1}
          sx={{
            width: "100%", // Ensure full width for the container
            maxWidth: "1200px", // Limit maximum width for better alignment
          }}
        >
          <MKTypography variant="h4" color="white" gutterBottom>
            Terms and Conditions
          </MKTypography>
          <MKBox
            display="flex"
            flexDirection="column"
            gap={2} // Space between paragraphs
            sx={{ width: "100%", margin: "0 auto" }} // Full width container
          >
            <MKBox
              sx={{
                backgroundColor: "#f5f5f5", // Light gray background
                borderRadius: "8px", // Rounded corners
                padding: "16px", // Padding for spacing
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow
              }}
            >
              <MKTypography variant="body1" color="black">
                Welcome to Guardian Shield. By accessing our services, you agree to comply with and
                be bound by the following terms and conditions. If you do not agree with any part of
                these terms, please do not use our services.
              </MKTypography>
            </MKBox>

            <MKBox
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
                padding: "16px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <MKTypography variant="body1" color="black">
                <strong>1. Acceptance of Terms:</strong> By using our website and services, you
                accept and agree to these terms and conditions in full.
              </MKTypography>
            </MKBox>

            <MKBox
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
                padding: "16px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <MKTypography variant="body1" color="black">
                <strong>2. Changes to Terms:</strong> We may revise these terms at any time by
                updating this page. It is your responsibility to check this page from time to time
                to review the terms.
              </MKTypography>
            </MKBox>

            <MKBox
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
                padding: "16px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <MKTypography variant="body1" color="black">
                <strong>3. Privacy Policy:</strong> Your use of our website is also governed by our
                Privacy Policy, which outlines how we collect and use personal information.
              </MKTypography>
            </MKBox>

            <MKBox
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
                padding: "16px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <MKTypography variant="body1" color="black">
                <strong>4. Limitation of Liability:</strong> Guardian Shield shall not be liable for
                any damages arising from the use of or inability to use the services provided.
              </MKTypography>
            </MKBox>

            <MKBox
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
                padding: "16px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <MKTypography variant="body1" color="black">
                <strong>5. Governing Law:</strong> These terms shall be governed by and construed in
                accordance with the laws of the State of Texas.
              </MKTypography>
            </MKBox>

            <MKBox
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
                padding: "16px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <MKTypography variant="body1" color="black">
                If you have any questions about these terms, please contact us at{" "}
                <a href="mailto:calvarado333@yahoo.com" style={{ color: "black" }}>
                  calvarado333@yahoo.com
                </a>
                .
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
      </MKBox>

      {/* Footer Section */}
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Terms;
