// Updated Team.js
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import HorizontalTeamCard from "UI/Cards/TeamCards/HorizontalTeamCard";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={8}
      px={{ xs: 3, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white" gutterBottom>
              The Executive Team
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8}>
              There&apos;s nothing I really wanted to do in life that I wasn&apos;t able to master.
            </MKTypography>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={3}>
              <HorizontalTeamCard
                image="/images/me.jpg" // Pass the correct prop name
                name="Cristofher Alvarado"
                position={{ color: "info", label: "Independent Insurance Broker" }}
                description="Dedicated to helping clients find the best insurance solutions to protect what matters most."
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
