import React from "react";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "UI/Navbars/DefaultNavbar";
import DefaultFooter from "UI/Footers/index.js";
import routes from "routes";
import footerRoutes from "footer.routes";
import "App.css";

function Privacy() {
  const privacyPolicies = [
    {
      question: "What information do we collect?",
      answer:
        "We collect personal information that you provide to us, such as your name, email address, phone number, and any other details you provide when contacting us or using our services.",
    },
    {
      question: "How do we use your information?",
      answer:
        "We use your information to provide and improve our services, communicate with you, and fulfill your requests. We may also use your information to send you marketing communications if you opt-in.",
    },
    {
      question: "Do we share your information?",
      answer:
        "We do not sell or rent your personal information to third parties. We may share your information with trusted partners who assist us in operating our website or conducting our business, as long as those parties agree to keep this information confidential.",
    },
    {
      question: "How do we protect your information?",
      answer:
        "We implement a variety of security measures to maintain the safety of your personal information. This includes using encryption and secure servers to protect data.",
    },
    {
      question: "What are your rights regarding your information?",
      answer:
        "You have the right to access, correct, or delete your personal information. You may also have the right to withdraw consent for processing your data at any time.",
    },
    {
      question: "How can you contact us?",
      answer:
        "If you have any questions about our privacy policy or the information we hold about you, please contact us via the contact information provided on our website.",
    },
  ];

  return (
    <>
      {/* Navbar Section */}
      <MKBox position="fixed" top="0.5rem" width="100%" zIndex={10}>
        <DefaultNavbar routes={routes} />
      </MKBox>

      {/* Main Content Section */}
      <MKBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        py={6}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(/images/bg-about-us.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
          position: "relative",
        }}
      >
        <MKBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          zIndex={1}
          sx={{
            width: "100%", // Ensure full width for the container
            maxWidth: "1200px", // Limit maximum width for better alignment
          }}
        >
          <MKTypography variant="h4" color="white" gutterBottom>
            Privacy Policy
          </MKTypography>
          <MKBox
            display="flex"
            flexDirection="column"
            gap={2} // Space between questions
            sx={{ width: "100%", margin: "0 auto" }} // Full width container
          >
            {privacyPolicies.map((policy, index) => (
              <MKBox
                key={index}
                sx={{
                  backgroundColor: "#f5f5f5", // Light gray background
                  borderRadius: "8px", // Rounded corners
                  padding: "16px", // Padding for spacing
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow
                }}
              >
                <MKTypography variant="h6" color="black">
                  {policy.question}
                </MKTypography>
                <MKTypography
                  variant="body1"
                  color="black"
                  sx={{
                    margin: "10px 0", // Add margin for spacing
                  }}
                >
                  {policy.answer}
                </MKTypography>
              </MKBox>
            ))}
          </MKBox>
        </MKBox>
      </MKBox>

      {/* Footer Section */}
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Privacy;
