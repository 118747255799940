// @mui icons
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Guardian Shield",
    image: "/logos/guardian_shield.png", // Reference the image directly from the public folder
    route: "/",
    className: "footer-logo",
  },
  socials: [
    {
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/calvarado_96/",
    },
    {
      icon: <GitHubIcon />,
      link: "https://github.com/kingwizard96",
    },
  ],
  menus: [
    {
      name: "Guardian Shield",
      items: [{ name: "About Me", route: "/pages/landing-pages/About" }],
    },
    {
      name: "Resources",
      items: [
        { name: "FAQs", route: "/pages/landing-pages/FAQs" }, // Replace with your actual route
      ],
    },
    {
      name: "Clinics",
      items: [{ name: "Clinics", route: "/pages/landing-pages/Clinics" }],
    },
    {
      name: "Legal",
      items: [
        { name: "Terms & Conditions", route: "/pages/landing-pages/Terms" }, // Replace with your actual route
        { name: "Privacy Policy", route: "/pages/landing-pages/Privacy" }, // Replace with your actual route
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} Guardian Shield by{" "}
      <MKTypography
        component="a"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Cristofher Alvarado
      </MKTypography>
      .
    </MKTypography>
  ),
};
