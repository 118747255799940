import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Button,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";

function DefaultNavbar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [blogAnchorEl, setBlogAnchorEl] = useState(null);
  const theme = useTheme();

  const handleMobileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleBlogMenuOpen = (event) => {
    setBlogAnchorEl(event.currentTarget);
  };

  const handleBlogMenuClose = () => {
    setBlogAnchorEl(null);
  };

  useEffect(() => {
    const resizeListener = () => {
      if (window.innerWidth >= theme.breakpoints.values.md) {
        handleMobileMenuClose();
        handleBlogMenuClose();
      }
    };
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [theme.breakpoints.values.md]);

  const menuItems = [
    { text: "Home", path: "/" },
    { text: "About", path: "/pages/landing-pages/About" },
    { text: "Clinics", path: "/pages/landing-pages/Clinics" },
    { text: "Blogs", path: "" },
    { text: "Schedule a Call", path: "https://calendly.com/calvarado1996" }, // Added Schedule a Call
  ];

  const blogSubItems = [
    { text: "Life Happens", path: "https://lifehappens.org/" },
    {
      text: "Compare Medicare",
      path: "https://www.medicare.gov/basics/get-started-with-medicare/get-more-coverage/your-coverage-options/compare-original-medicare-medicare-advantage",
    },
    { text: "Health Marketplace", path: "https://www.healthcare.gov/choose-a-plan/" },
  ];

  const renderMenuItems = menuItems.map((item) =>
    item.text === "Blogs" ? (
      <MenuItem key={item.text} onClick={handleBlogMenuOpen} sx={{ color: "green" }}>
        <Typography color="inherit" variant="body1">
          {item.text}
        </Typography>
      </MenuItem>
    ) : item.path.startsWith("http") ? (
      <MenuItem key={item.text} onClick={handleMobileMenuClose} sx={{ color: "green" }}>
        <a
          href={item.path}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Typography variant="body1">{item.text}</Typography>
        </a>
      </MenuItem>
    ) : (
      <MenuItem key={item.text} onClick={handleMobileMenuClose} sx={{ color: "green" }}>
        <Link to={item.path} style={{ textDecoration: "none", color: "inherit" }}>
          <Typography variant="body1">{item.text}</Typography>
        </Link>
      </MenuItem>
    )
  );

  const renderBlogSubItems = blogSubItems.map((item) => (
    <MenuItem key={item.text} onClick={handleBlogMenuClose} sx={{ color: "green" }}>
      <a
        href={item.path}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        {item.text}
      </a>
    </MenuItem>
  ));

  return (
    <AppBar
      position="fixed"
      color="transparent"
      sx={{
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        marginBottom: "16px",
        borderRadius: "5px",
        top: "16px",
        left: "16px",
        right: "16px",
        maxWidth: "calc(100% - 32px)",
        height: "64px",
      }}
    >
      <Toolbar sx={{ minHeight: "64px" }}>
        <Box
          component="img"
          loading="lazy"
          src="/logos/guardian_shield.png"
          alt="Logo"
          sx={{
            width: "100px",
            height: "100px",
            borderRadius: "50%",
            marginRight: "16px",
            position: "relative",
            top: "5px",
          }}
        />

        <Typography
          variant="h6"
          component={Link}
          to="/"
          sx={{ flexGrow: 1, textDecoration: "none", color: "green" }}
        >
          GUARDIAN SHIELD
        </Typography>

        <Box display={{ xs: "none", md: "flex" }}>
          {menuItems.map((item) =>
            item.text === "Blogs" ? (
              <Button
                key={item.text}
                color="inherit"
                aria-controls="blog-menu"
                aria-haspopup="true"
                onClick={handleBlogMenuOpen}
                sx={{
                  color: "green",
                  "&:hover": {
                    color: "#ffffff",
                    backgroundColor: "rgba(255, 255, 255, 0.3)",
                    borderRadius: "4px",
                  },
                }}
              >
                {item.text}
              </Button>
            ) : item.path.startsWith("http") ? (
              <Button
                key={item.text}
                href={item.path}
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
                sx={{
                  color: "green",
                  "&:hover": {
                    color: "#ffffff",
                    backgroundColor: "rgba(255, 255, 255, 0.3)",
                    borderRadius: "4px",
                  },
                }}
              >
                {item.text}
              </Button>
            ) : (
              <Button
                key={item.text}
                component={Link}
                to={item.path}
                color="inherit"
                sx={{
                  color: "green",
                  "&:hover": {
                    color: "#ffffff",
                    backgroundColor: "rgba(255, 255, 255, 0.3)",
                    borderRadius: "4px",
                  },
                }}
              >
                {item.text}
              </Button>
            )
          )}
        </Box>

        <Box display={{ xs: "flex", md: "none" }}>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMobileMenuOpen}>
            <MenuIcon />
          </IconButton>
        </Box>

        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMobileMenuClose}
        >
          {renderMenuItems}
        </Menu>

        <Menu
          id="blog-menu"
          anchorEl={blogAnchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          keepMounted
          open={Boolean(blogAnchorEl)}
          onClose={handleBlogMenuClose}
        >
          {renderBlogSubItems}
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

export default DefaultNavbar;
