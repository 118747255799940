// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "UI/Navbars/DefaultNavbar";
import DefaultFooter from "UI/Footers/index.js";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images

import Team from "./sections/Team";

function AboutUs() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "schedule",
          color: "default",
        }}
        transparent
        light
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(/images/besomeone.png)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Licensed Texas Insurance Agent
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              Helping you protect what matters most with Life, Health, and Medicare insurance
              solutions.
            </MKTypography>
            <MKButton
              color="default"
              sx={{ color: ({ palette: { dark } }) => dark.main }}
              onClick={() => (window.location.href = "mailto:cristofheralvarado@aol.com")}
            >
              Contact Me
            </MKButton>
            <MKTypography variant="h6" color="white" mt={8} mb={1}>
              Find me on
            </MKTypography>
            <MKBox display="flex" justifyContent="center" alignItems="center">
              <MKTypography
                component="a"
                variant="body1"
                color="white"
                href="https://www.instagram.com/calvarado_96/"
                mr={3}
              >
                <i className="fab fa-instagram" />
              </MKTypography>
              <MKTypography
                component="a"
                variant="body1"
                color="white"
                href="https://github.com/Kingwizard96"
                mr={3}
              >
                <i className="fab fa-github" />
              </MKTypography>
              <MKTypography
                component="a"
                variant="body1"
                color="white"
                href="https://www.linkedin.com/in/cristofher-alvarado-370891254/"
              >
                <i className="fab fa-linkedin" />
              </MKTypography>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {/* Add License Section */}
        <Box sx={{ textAlign: "center", my: 4 }}>
          <MKTypography variant="h4" mb={2}>
            My Texas Insurance License
          </MKTypography>
          <img
            src="/images/license.png" // Direct path from public/images
            alt="Texas Insurance License"
            style={{ maxWidth: "100%", borderRadius: "8px" }}
          />
        </Box>

        {/* Revamped About Me Section */}
        <Box sx={{ textAlign: "center", my: 4 }}>
          <MKTypography variant="h4" mb={2}>
            About Me
          </MKTypography>
          <MKTypography variant="body1" color="inherit" mb={4}>
            {" "}
            {/* Updated here */}
            My name is Cristofher Alvarado, an independent insurance agent based in Texas. My goal
            is to guide you toward the best Life, Health, and Medicare insurance solutions, ensuring
            that you and your loved ones are protected in every stage of life.
          </MKTypography>
          <MKTypography variant="h6" color="info" mb={2}>
            A Little More About Me:
          </MKTypography>
          <MKTypography variant="body1" color="inherit" mb={4}>
            {" "}
            {/* Updated here */}
            Beyond insurance, I’m passionate about creativity and learning. I love to draw, read,
            and constantly seek new knowledge. Currently, I’m developing my own agent page to
            provide a more personalized experience for my clients. Born and raised in Houston, I’ve
            always been driven to explore the world, both through travel and imagination. I’m also a
            huge fan of sci-fi and fantasy. Whether it’s the epic battles of &quot;Lord of the
            Rings,&quot;, the magical world of &quot;Harry Potter,&quot; or the galaxies of
            &quot;Star Wars,&quot;, I love diving into different realms of storytelling.
          </MKTypography>
          <MKTypography variant="h6" color="info" mb={2}>
            Experience and Expertise:
          </MKTypography>
          <MKTypography variant="body2" color="inherit">
            {" "}
            {/* Updated here */}
            - Licensed in Life, Health, and Medicare Insurance <br />
            - Serving individuals, families, and small businesses <br />- Specialized in life
            insurance, including term, whole life,index universal life, annuties, and final expense
          </MKTypography>
        </Box>
        <Team />

        {/* Optional: Testimonials or Certifications Section */}
        <Box sx={{ textAlign: "center", my: 4 }}> </Box>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;
