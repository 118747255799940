// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "UI/Navbars/DefaultNavbar";

// Routes
import routes from "routes";

function Blog() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          color: "info",
        }}
        transparent
        light
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundColor: "gray", // Default background color if no image
            display: "grid",
            placeItems: "center",
          }}
        >
          {/* Optionally, add content or text here */}
          <h1>Blog Page</h1>
        </MKBox>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          {/* Optionally, add content or text here */}
          <h2>Card Content</h2>
        </Card>
      </MKBox>
    </>
  );
}

export default Blog;
